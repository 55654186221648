@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');


.continue-button {
    display: inline-flex;
    padding: 8px 32px;
    align-items: center;
    gap: 24px;

    border-radius: 51px;
    border: 2px solid var(--primaryturquoise, #1ACFDA);
}

.continue-button-text {
    color: var(--primarywhite, #FFF);
    font-size: 24px;
    font-weight: 700;
    font-family: Poppins;
    line-height: 110%;
}

.back-button {
    display: flex;
    padding: 8px 32px;
    align-items: center;
    gap: 24px;

    border-radius: 51px;
    border: 2px solid var(--primaryred, #E63328);
}

.back-button-text {
    color: var(--primarywhite, #FFF);
    font-size: 24px;
    font-family: Poppins;
    font-weight: 700;
    line-height: 110%;
}