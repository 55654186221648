.badge {
    margin-left: 290.5px;
    position: absolute;
    margin-top: -400px;
}

.title-badgenumber {
    margin-top: 200px;
}

.badgenumber-input {
    display: flex;
    width: 750px;
    height: 64px;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    margin-left: 165px;
    margin-top: 80px;
    border-radius: 16px;
    border: 1px solid var(--primaryturquoise, #1ACFDA);
    background: linear-gradient(135deg, rgba(26, 206, 218, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    color: white;
    font-size: 24px;
}

.badgenumber-input:focus {
    outline: none;
    border: 2px solid var(--primaryturquoise, #1ACFDA);
    
}

.badgenumber-input[type=number] {
    color: white;
    padding: 16px 24px;
    font-size: 24px;
}

.button-block-badgenumber {
    margin-top: 100px;
}