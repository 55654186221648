/* animations.css */
@keyframes slide-in {
    from {
        transform: translateX(-100%) translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0) translateY(0);
        opacity: 1;
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0) translateY(0);
        opacity: 1;
    }
    to {
        transform: translateX(-100%) translateY(100%);
        opacity: 0;
    }
}

.slide-in {
    animation: slide-in 1s forwards;
}

.slide-out {
    animation: slide-out 1s forwards;
}

/* Position the image at the bottom left corner */
.image-slide {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 125px;
}
