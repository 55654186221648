.kluskip-content {
    width: 650px;
    margin-left: 215px;
    margin-top: -150px;
}

.kluskip-content p {
    color: #FFF;
    font-size: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

/* DROPDOWN */
.dropbtn {
    display: flex;
    width: 750px;
    padding: 16px 24px;
    align-items: center;
    justify-content: space-between; /* Used this instead of 'gap' for better centering */
    border-radius: 8px;
    background: rgba(26, 202, 218, 0.10);
    color: #FFF;
    font-size: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-top: 50px;
    cursor: pointer;
    transition: background-color 0.3s; /* Added a transition for smoother color change */
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    background-color: rgba(34, 87, 162, 1);
    width: 750px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); /* Adjusted shadow for subtleness */
    z-index: 111;
    font-size: 24px;
    border-radius: 8px; /* Added border-radius to match the dropbtn */
    border-top-right-radius: 0px; /* Adjusted border radius to match the dropbtn */
    border-top-left-radius: 0px; /* Adjusted border radius to match the dropbtn */
}

.dropdown-content a {
    color: white; /* Changed text color to white for better visibility */
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s; /* Added a transition for smoother color change */
}

.dropdown-content a:hover {
    background-color: rgba(26, 202, 218, 0.60); /* Adjusted the hover effect for a slight darkening effect */
}

.dropdown:hover .dropbtn {
    background-color: rgba(26, 202, 218, 0.40);
}

.dropbtn-open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: rgba(26, 202, 218, 0.60); /* Adjusted the hover effect for a slight darkening effect */
}

.dropdown-input {
    margin: 12px 16px;
    display: flex;
    background-color: transparent;
    border: none;
    color: white;
}

.dropdown-input:focus {
    outline: none;
    color: white;
}


.comment-box {
    margin-top: 50px;
}

.input-comment-kluskip {
    position: relative !important;
    margin-left: 0px !important;
}