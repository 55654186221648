.thanks-chick {
    margin-top: -715px;
    margin-left: 295px;
    position: absolute;
}

.header-thanks {
    margin-top: 100px;
    width: 1080px;
}

.header-thanks .subtitle-thanks {
    margin-top: 60px;
}

.stripes-bg-thanks {
    margin-top: 675px;
}