@import url('https://fonts.googleapis.com/css2?family=Livvic:wght@400;500;600;700;900&display=swap');

.bg {
    width: 1500px;
    height: 944px;
    flex-shrink: 0;
    position: absolute;
    z-index: -1;
}

.header {
    position: absolute;
    z-index: 2;
    margin-top: -650px;
    width: 1080px;
}

.header .title {
    color: #FFF;
    text-align: center;
    font-size: 80px;
    font-family: Livvic;
    font-weight: 900;
    line-height: 130%;
    text-transform: uppercase;
}

.header .subtitle {
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-family: Poppins;
    line-height: 130%;
    margin-top: 50px;
    width: 849px;
    margin-left: auto;
    margin-right: auto;
}

.important-text {
    position: relative;
    z-index: 2;
    display: inline-block;  /* Added to contain the pseudo-element */
    padding: 1px 10px;  /* Added to create some space around the text */
}

.important-text::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #71D1D4;
    clip-path: polygon(0 0, 100% 0, 98% 100%, 2% 100%);
    z-index: -1;
}


.big-button {
    display: flex;
    width: 389px;
    height: 400px;
    padding: 0px 47px 40px 48px;
    flex-direction: column;
    align-items: center;
    gap: 61px;
    flex-shrink: 0;

    border-radius: 32px;
    background: linear-gradient(135deg, rgba(26, 207, 218, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(16px);
}

.big-button-active {
    border-radius: 32px;
    border: 8px solid var(--primaryturquoise, #1ACFDA);
    background: linear-gradient(135deg, rgba(0, 194, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(16px);
}

.big-button:active {
    border-radius: 32px;
    border: 8px solid var(--primaryturquoise, #1ACFDA);
    background: linear-gradient(135deg, rgba(0, 194, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(16px);
}

.big-button-text {
    color: var(--primarywhite, #FFF);
    text-align: center;
    font-size: 32px;
    font-family: Livvic;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
}

.sad-text {
    margin-top: 20px;
}

.chick-1 {
    width: 294px;
    height: 325px;
    flex-shrink: 0;
    margin-top: -60px;
}

.chick-2 {
    width: 266.027px;
    height: 247.261px;
    flex-shrink: 0;
    padding-top: 20px;
}

.long-button {
    width: 826px;
    height: 160px;
    flex-shrink: 0;

    border-radius: 32px;
    background: linear-gradient(135deg, rgba(26, 207, 218, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(16px);
}

.long-button-active {
    border-radius: 32px;
    border: 8px solid var(--primaryturquoise, #1ACFDA);
    background: linear-gradient(135deg, rgba(26, 207, 218, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(16px);
}

.long-button:active {
    border-radius: 32px;
    border: 8px solid var(--primaryturquoise, #1ACFDA);
    background: linear-gradient(135deg, rgba(26, 207, 218, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(16px);
}

.long-button-text {
    color: var(--primarywhite, #FFF);
    font-size: 32px;
    font-family: Livvic;
    font-weight: 700;
    line-height: 110%;
    text-transform: uppercase;
    margin-top: 62px;
    margin-left: 56px;
}

.tools {
    margin-top: 36px;
    margin-left: 50px;
}

.long-button:active .tools,
.long-button:target .tools {
    margin-top: 21px;
    margin-left: 50px;
}

.long-button-active .tools {
    margin-top: 21px;
}

.win-bg {
    margin-left: 679px;
    margin-top: 140px;
    z-index: 1;
    position: absolute;
    width: 405.404px;
    flex-shrink: 0;
}

.win-egg {
    position: absolute;
    width: 96.702px;
    height: 137.073px;
    transform: rotate(-4.876deg);
    flex-shrink: 0;
    z-index: 2;
    margin-left: 860px;
    margin-top: 140px;
}

.win-text {
    z-index: 2;
    color: var(--primarywhite, #FFF);
    text-align: center;
    font-size: 28px;
    font-family: Livvic;
    font-weight: 700;
    line-height: 130%;
    text-transform: uppercase;

    margin-left: 789px;
    margin-top: 300px;
    position: absolute;
}

.stripes-bg {
    margin-left: 922px;
    margin-top: 175px;
}