.sad-form {
    display: flex;
    width: 760px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    margin-left: 160px;
    height: 950px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: -200px;
}

.sad-form::-webkit-scrollbar {
    display: none;
}

.sad-form .flex {
    display: flex;
    width: 760px;
    padding: 16px 24px;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    background: rgba(26, 207, 218, 0.10);
}

.sad-form .flex {
    color: #FFF;
    font-size: 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.sad-form .flex .selector {
    display: flex;        /* Add flex layout to center align the child dot */
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 25.333px;
    border: 1px solid var(--primaryturquoise, #1ACFDA);
    position: relative;     /* Establish stacking context */
}

.sad-form .flex .selector .selector-inside {
    display: none;        /* Initially hidden */
    width: 24px;          /* Set your desired dot size */
    height: 24px;
    background-color: var(--primaryturquoise, #1ACFDA);
    border-radius: 50%;   /* Make it round */
}

.sad-form .flex.selectedBothering .selector .selector-inside {
    display: block;       /* Show the dot when parent is active */
}

.sad-form .flex input[type="radio"] {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    accent-color: #1ACFDA;
    background-color: blue;
}


.button-block-sad {
    margin-top: 100px;
}

.custom-input {
    background-color: transparent;
    color: #FFF;
    display: flex;
    width: 656px;
    padding: 0px 574px 2px 0px;
    align-items: center;
    flex-shrink: 0;
    border-bottom: 1px solid var(--primaryturquoise, #1ACFDA);
    border-top: none;
    border-left: none;
    border-right: none;
}

.custom-input:enabled {
    border-bottom: 1px solid var(--primaryturquoise, #1ACFDA);
    outline: none;
}

.sad-form .flex.selectedBothering {
    border-radius: 8px;
    border: 1px solid var(--primaryturquoise, #1ACFDA);
    background: rgba(26, 202, 218, 0.40);
}

.sad-form .flex.selectedBothering .selector {
    width: 40px;
    height: 40px;
    background: transparent;
    border: 4px solid var(--primaryturquoise, #1ACFDA);
}


