.header-happy {
    margin-top: -800px;
}

.content {
    margin-top: -150px
}

.subtext {
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-family: Poppins;
    line-height: 130%;
}

.input-comment {
    display: flex;
    width: 750px;
    height: 407px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid var(--primaryturquoise, #1ACFDA);
    background: linear-gradient(135deg, rgba(26, 206, 218, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);

    margin-left: 165px;
    margin-right: 165px;

    z-index: 5 !important;
    position: absolute;
}

.input-comment:focus {
    outline: none;
    border: 2px solid var(--primaryturquoise, #1ACFDA);
    
}

.input-comment[type=text] {
    color: white;
    padding: 16px 24px;
    font-size: 24px;
}

.button-block {
    margin-top: 500px;
}

.back-button {
    margin-right: 10px;
}

.continue-button {
    margin-left: 10px;
}