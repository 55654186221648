.won-egg {
    margin-top: -400px;
    margin-left: 360px;
    width: 359.226px;
    height: 509.194px;
    transform: rotate(-4.876deg);
    flex-shrink: 0;
}

.header-won {
    margin-top: 100px;
    width: 1080px;
}