.arrow-one {
    position: absolute;
    margin-top: 1000px;
    margin-left: 550px;
    rotate: 165deg;
}

.arrow-two {
    position: absolute;
    margin-top: 950px;
    margin-left: 375px;
    rotate: 180deg;
}


.circle-one,
.circle-two,
.circle-three,
.circle-four {
    position: absolute;
}

.circles {
    position: absolute;
    width: 558.663px;
    height: 558.663px;
    margin-left: 260.6685px;
    margin-right: 260.6685px;
    margin-top: 225px;
}

.circle-one {
    width: 558.663px;
    height: 558.663px;
    flex-shrink: 0;
    border-radius: 279.332px;
    background: rgba(26, 207, 218, 0.20);
}

.circle-two {
    width: 468.069px;
    height: 468.069px;
    flex-shrink: 0;

    border-radius: 279.332px;
    background: rgba(26, 207, 218, 0.20);
    
    margin-left: 45px;
    margin-top: 45px;
}

.circle-three {
    width: 380.495px;
    height: 380.495px;
    flex-shrink: 0;
    border-radius: 279.332px;
    background: rgba(26, 207, 218, 0.20);

    margin-left: 90px;
    margin-top: 90px;
}

.circle-four {
    width: 295.941px;
    height: 295.941px;
    flex-shrink: 0;

    border-radius: 279.332px;
    background: rgba(26, 207, 218, 0.20);

    margin-left: 135px;
    margin-top: 135px;
}

.badge-img {
    position: absolute;
    z-index: 10;
    margin-left: 37px;
    margin-top: 37px;
}

.title {
    color: #FFF;
    text-align: center;
    font-size: 80px;
    font-family: Livvic;
    font-weight: 900;
    line-height: 130%;
    text-transform: uppercase;
    margin-top: 80px;
}

.title-scan {
    margin-top: -800px
}

.subtitle {
    display: flex;
    width: 849px;
    flex-direction: column;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-size: 32px;
    font-family: Poppins;
    line-height: 130%;
    font-weight: 300;
    margin-left: 116px;
    margin-top: 50px;
}