@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    height: 1920px;
    width: 1080px;
    overflow: hidden;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

body {
    background: #3F1F91;
}